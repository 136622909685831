// export interface File {
//   name?: string;
// }

export interface Project {
  project_pitch: string;
  project_description: string;
  project_stage: string;
  project_priority: string;
  project_time_spent: string;
}

export interface Profile {
  elementId: string;
}

export interface TimeSpent {
  time: number;
  duration: string;
}

export interface User {
  first_name: string;
  last_name: string;
  picture?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  password?: string;
  environment?: string;
}

export interface ReducerAction {
  type: string;
  value?: any;
}

export type ReducerDispatch = (action: ReducerAction) => void;

export interface Feedback {
  question: string;
}

// eslint-disable-next-line no-shadow
export enum FeedbackTypeEnum {
  QUESTION = 'QUESTION',
  POLL = 'POLL',
}

export type Feedbacks = FeedbackQuestion | FeedbackPoll;

export interface FeedbackViewModel {
  id?: number;
  name: string;
  type: ProjectType;
  description: string;
  images: string[];
  feedbacks: FeedbackQuestion[] | FeedbackPoll[];
  creator?: Partial<User> & {
    isOwner?: boolean;
  };
  createdAt?: Date;
}

export interface FeedbackQuestion {
  id?: number;
  type: FeedbackTypeEnum;
  value: {
    title: string;
    image: string;
    options: any;
  };
  answer?: string;
  answers?: FeedbackAnswer[];
}

export interface FeedbackPoll {
  id?: number;
  type: FeedbackTypeEnum;
  answer?: string;
  answers?: FeedbackAnswer[];
  percentages?: any;
  value: {
    title: string;
    options: {
      question: string;
      image: string;
    }[];
  };
}

export interface FeedbackAnswer {
  answer: string;
  createdAt: Date;
  user: {
    id: number;
    name: string;
    picture?: string;
    username: string;
  };
}

// Project
export interface ProjectViewModel {
  id: number;
  name: string;
  description: string;
  tag: string;
  phase: string;
  thumbnail?: string | ProjectAsset;
  type: ProjectType;
  images: string[] | ProjectAsset[];
  comments: ProjectPostComment[];
  emails?: string[];
  feedbacks?: FeedbackQuestion[] | FeedbackPoll[];
  creator?: Partial<User> & {
    isOwner?: boolean;
    picture?: string;
  };
  invitations: UserInvitation[];
  status?: ProjectStatus;
  groups?: GroupViewModel[];
  counters: ProjectCounters;
  featured: boolean;
  following: boolean;
  shareHash?: string;
  private: boolean;
  audits: ProjectAuditViewModel[];
  createdAt: Date;
  updatedAt?: Date;
}

export interface ProjectPostViewModel {
  id?: number;
  description: string;
  image: string;
  expiration?: number;
  isPrivate?: boolean;
  creator?: Partial<User>;
  createdAt?: Date;
  updatedAt?: Date;
  invitations: UserInvitation[];
  groups: GroupViewModel[];
  type: ProjectType;
}

export enum PostMetadataType {
  DRAWING = 'DRAWING',
  MARKER = 'MARKER',
}

export interface ProjectPostComment {
  id?: number;
  value: string;
  image?: string;
  user?: UserViewModel;
  metadatas?: ProjectPostCommentMetadata[];
  replies?: ProjectPostComment[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ProjectPostCommentMetadata {
  type: PostMetadataType;
  value: any;
}

export interface ProjectCounters {
  assets: {
    public: number;
    private: number;
  };
}

export interface ProjectAsset {
  url: string;
  private: boolean | null;
  name?: string;
  lastModified?: number;
  createdAt?: Date;
}

export interface UploadFilesJson {
  key: string;
  originalname: string;
  location: string;
}

export enum UserType {
  PRO = 'PRO',
  FREE = 'FREE',
}

export type UserViewModel = {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  type?: UserType;
  password?: string;
  picture?: string | null;
  token?: string;
  username?: string;
  description?: string;
  location?: string;
  twitter?: string;
  instagram?: string;
  linkedin?: string;
  website?: string;
  following?: boolean;
  projectCount?: number;
  metadata?: {
    onboarded?: boolean;
    student?: boolean;
  };
};

export interface UserInvitation {
  id: number;
  email: string;
}

export enum FeedbackProtectionType {
  DATE = 'DATE',
  PASSWORD = 'PASSWORD',
}

export enum FeedbackErrors {
  LINK_EXPIRED = 'link expired',
}

export enum ProjectType {
  DEFAULT = 'DEFAULT',
  QUICK_FEEDBACK = 'QUICK_FEEDBACK',
  POST = 'POST',
}

export interface AuthToken {
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

export enum ProjectStatus {
  DRAFT = 'DRAFT',
  DONE = 'DONE',
}

export interface GroupViewModel {
  id?: number;
  name: string;
  description?: string;
  image: string;
  members: string[];
  projects: number[] | ProjectViewModel[];
  settings: any;
  owner: Partial<UserViewModel>;
  updatedAt: Date;
}

export enum GroupSettings {
  PRIVATE = 'private',
  ALLOW_INVITES = 'allow_invites',
  ALLOW_USER_AUTO_JOIN = 'allow_users_auto_join',
  PENDING_INVITATION = 'pending_invitation',
}

export interface NotificationMessageViewModel {
  id: string;
  to: string;
  title: string;
  body: string;
  picture: string;
  username: string;
  url: string;
  createdAt: Date;
  readAt?: Date;
}

export interface NotificationPaging {
  total: number;
  unread: number;
  page: number;
}

export interface NotificationResult {
  results: NotificationMessageViewModel[];
  paging: NotificationPaging;
}

export interface AccessTokenViewModel {
  token: string;
  spotsLeft: number;
}

export interface ProjectAuditViewModel {
  id: number;
  change: any;
  createdAt: Date;
}

export interface FeedViewModel {
  results: (ProjectPostViewModel | ProjectViewModel)[];
  offset: number;
  limit: number;
  hasMore: boolean;
}

export interface BoardItem {
  id?: number;
  value: string | File;
  order?: number;
  comments?: BoardComment[];
  createdAt: Date;
  updatedAt?: Date;
  boardId?: number;
  boardColumnId?: number;
}
export interface BoardColumn {
  id?: number;
  name: string;
  placeholder?: string;
  order: number;
  items: BoardItem[];
  updatedAt?: Date;
}

export interface Board {
  id?: number;
  name: string;
  description?: string;
  image: string;
  following: boolean;
  private: boolean;
  status: BoardStatus;
  state: BoardState;
  columns: BoardColumn[];
  audits?: BoardAudit[];
  createdAt?: Date;
  updatedAt?: Date;
  user?: UserViewModel;
  favorited?: boolean;
  shares: BoardShare[];
}

export type BoardShare = {
  id: number;
  createdAt: Date;
  expiresAt: Date | null;
  hash: string;
};

export interface BoardSimplified {
  id: number;
  name: string;
  image: string;
  updatedAt: Date;
  createdAt: Date;
  user?: UserViewModel;
  items: string[];
}

export interface BoardStat {
  date: string;
  count: number;
  level: number;
}

export interface ImagePreview {
  url: string;
  name?: string;
  lastModified?: number;
}

export enum BoardStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  KILLED = 'KILLED',
  COMPLETED = 'COMPLETED',
}

export enum BoardState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export interface BoardAudit {
  id: number;
  change: any;
  createdAt: Date;
  boardId?: number;
  boardName?: string;
}

export enum BoardCommentMetadataType {
  DRAWING = 'DRAWING',
  MARKER = 'MARKER',
}

export interface BoardCommentMetadata {
  type: BoardCommentMetadataType;
  value: any;
}

export interface BoardComment {
  id?: number;
  value: string;
  user?: UserViewModel;
  metadatas?: BoardCommentMetadata[];
  replies?: BoardComment[];
  createdAt?: Date;
  updatedAt?: Date;
}

export enum PubSubEvent {
  PROJECT_DELETED = 'PROJECT_DELETED',
  TEAM_DELETED = 'TEAM_DELETED',
}

export enum GoogleSignInState {
  SIGN_UP_NEW_USER = 'new-user',
}

export type TeamViewModel = {
  id?: number;
  name: string;
  image: string;
  members?: string[] | Partial<UserViewModel>[];
  boards?: number[] | Partial<Board>[];
  owner: Partial<UserViewModel>;
  updatedAt: Date;
};

export type TeamSimplifiedViewModel = {
  id: number;
  name: string;
  image: string;
  members: number;
  boards: number;
  owner: Partial<UserViewModel>;
  updatedAt: Date;
};

export enum DashboardSettings {
  DOWNLOAD = 'download',
  FIRST_PROJECT = 'firstProject',
  VIDEO = 'video',
  RECENT_UPLOADS_SIDEBAR = 'recentUploadsSidebar',
  WELCOME_CARD = 'welcomeCard',
  SAMPLE_PROJECTS = 'sampleProjects',
}

const IS_DEV = process.env.NODE_ENV === 'development';
export const COMMUNITY_TEAM_ID = IS_DEV ? 3 : 2;
