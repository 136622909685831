import React, { useMemo } from 'react';
import { Container, Content, Header } from './Section.styles';
import Dropzone from '../Dropzone/Dropzone';

import { BoardColumn, BoardItem } from '../../../../typings';
import Name from './components/Name/Name';
import FileUtils from '../../../../utils/file.utils';

interface SectionProps {
  data: BoardColumn;
  onChange: (data: BoardColumn) => void;
  onMove: (from: number, to: number) => void;
  onImageClick: (item: BoardItem) => void;
  disabled: boolean;
  stack: {
    first: boolean;
    last: boolean;
    single: boolean;
  };
}

const Section: React.FC<SectionProps> = ({
  data,
  disabled,
  onChange,
  onImageClick,
  onMove,
  stack,
}: SectionProps) => {
  function getItemsOrdered(items: BoardItem[]): BoardItem[] {
    return items.map((item, index) => ({
      ...item,
      order: index,
    }));
  }

  function handleAdd(newItems: BoardItem[]): void {
    const itemsOrdered = getItemsOrdered([...newItems, ...data.items]);
    onChange({ ...data, items: itemsOrdered });
  }

  function handleMove(itemsReordered: BoardItem[]): void {
    onChange({ ...data, items: itemsReordered });
  }

  function handleItemChange(item: BoardItem): void {
    if (item.value !== '') {
      data.items[item.order as number] = item;
      onChange(data);
    }
  }

  function handleRemove(item: BoardItem) {
    const itemsUpdated = data.items.filter((i) => i.order !== item.order);
    const itemsOrdered = getItemsOrdered(itemsUpdated);
    onChange({ ...data, items: itemsOrdered });
  }

  const imageAssets = useMemo(
    (): number =>
      data.items.filter((item) => new FileUtils(item.value).isImage()).length,
    [data.items]
  );

  return (
    <Container id={`section-${data.order}`}>
      <Header>
        <Name
          name={data.name}
          placeholder={data.placeholder}
          totalAssets={imageAssets}
          stack={stack}
          disabled={disabled}
          onChange={(name) => onChange({ ...data, name })}
          onMoveLeft={() => onMove(data.order, data.order - 1)}
          onMoveRight={() => onMove(data.order, data.order + 1)}
          onDelete={() => onMove(data.order, data.order)}
          onClick={() => onImageClick(data.items[0])}
        />
      </Header>

      <Content>
        <Dropzone
          disabled={disabled}
          items={data.items}
          onAdd={handleAdd}
          onRemove={handleRemove}
          onInputChange={handleItemChange}
          onImageClick={onImageClick}
          onMove={handleMove}
          maxFiles={30}
        />
      </Content>
    </Container>
  );
};

export default Section;
