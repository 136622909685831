import styled, { createGlobalStyle } from 'styled-components/macro';
import CloseIcon from '@material-ui/icons/Close';

interface ContainerProps {
  open: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  right: 32px;
  top: 12px;
  z-index: 3;
  width: 304px;
  border-radius: 16px;
  background: ${({ theme }) => theme.gray2};
  transform: translateX(${({ open }) => (open ? '0' : '334px')});
  transition: all 0.3s ease;
  box-shadow: 0px 8px 8px -8px rgba(127, 128, 135, 0.2);
  border: 1px solid ${({ theme }) => theme.gray};
  padding: 16px 8px 8px 8px;
`;

export const Header = styled.header`
  height: 32px;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 20px;
  align-items: center;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 16px;
  gap: 4px;
  max-height: calc(100vh - 100px);

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }
`;

export const Close = styled(CloseIcon)`
  font-size: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.gray3};
`;

export const LockMainContent = createGlobalStyle`
  #content {
    overflow: hidden;
  }
`;
