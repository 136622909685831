import styled from 'styled-components/macro';
import { ButtonTransparent } from '../../../../../../components/Button/Button';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  align-content: flex-start;
  gap: 12px;
  padding-bottom: 40px;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const EnlargeButton = styled(ButtonTransparent)`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: ${({ theme }) => theme.white};
  transition: all 0.5s ease;
  cursor: pointer;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;
